import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';

import UtahDentalAssociationImage from '../../assets/images/publication-banners/uda.jpg';

const UtahDentalAssociation = (props) => (
  <Layout>
    <Helmet>
      <title>Utah Dental Association - Mills Publishing Inc.</title>
      <meta
        name="description"
        content={
          'Advertising and issue information information for the ' +
          new Date().getFullYear() +
          ' Utah Dental Association magazine. Please contact Mills Publishing for availability and rates.'
        }
      />
    </Helmet>
    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Utah Dental Association</h1>
          </header>
          <span className="image main">
            <img src={UtahDentalAssociationImage} alt="" />
          </span>
          <p className="box">
            The UDAction is the official magazine of the Utah Dental
            Association. The magazine is published bi-monthly and mailed
            throughout the state to all licensed dentists who are members of the
            Utah Dental Association. The magazine is an important tool for
            dentists to stay current on the latest techniques, equipment and
            services. The bi-monthly issues are as follows: January/February,
            March/April, May/June, July/August, September/October,
            November/December. There are approximately 2,000 members of the Utah
            Dental Association who receive the magazine on a regular basis.
          </p>
          <ul className="actions">
            <li>
              <a href="https://www.uda.org/" className="button special">
                <i className="icon fa-globe"> </i>Utah Dental Association
                Website
              </a>
            </li>
          </ul>
          <h2>
            Utah Dental Association {new Date().getFullYear()} Information
          </h2>
          <section className="box">
            <h3>Bi-Monthly Magazine</h3>
            <strong>Circulation</strong>: 2,000 per issue.
            <br />
            <strong>File Ready</strong>: 25th of the month prior to
            publication/issue.
            <br />
            <em>
              Note: All dates falling on weekends will be moved to the following
              Monday.
            </em>
          </section>
        </div>
      </section>
    </div>
  </Layout>
);

export default UtahDentalAssociation;
